.faq-body {
    display: flex;
    flex-direction: column;
    width: 1000px;
    text-align: left;
}

/************************** MOBILE **************************/
.faq-body-mobile {
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: left;
}

.faq-list-mobile {
    list-style-type: none;
    padding-left: 0px;
    margin-top: 0px;
}