.home-services-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .homeService-link {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 130px;
    text-align: center;
    margin-bottom: 55px;
    text-decoration: none;
    justify-content: space-between;
  }

  .homeService-link-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 130px;
    text-align: center;
    margin-bottom: 55px;
    text-decoration: none;
    justify-content: space-between;
  }

  .serviceImage {
    height: 75px;
  }