/************************** DESKTOP **************************/

.App-header {
    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
    min-height: 10vh;
    max-height: 40vh;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: var(--color-text);
    
  }
  
.header-container {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.logoContainer {
    display: flex;
    position: relative;
    border: 3px solid #8bac3e;
    z-index: 10;
    background-color: var(--color-background);
    left: 200px;
    top: 60px
}
  
.logoDimentions {
    height: 156px;
    width: 186px;
  }
  
.headerNav {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 20px;
  }
  
.nav-container {
    display: flex;
    flex-direction: row;
  }
  

  
.activeLink {
    color: var(--color-active-text);
  }

.navLinkPaddingRight {
    padding-right: 20px;
  }
  
.endNavContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 20px;
    margin-right: 200px;
  }
  
.mediaLinksContainer {
    display: flex;
    flex-direction: row;
  }
  
.mediaLink {
    display: flex;
    height: 40px;
    width: 40px;
    justify-content: space-between;
  }
  
.mediaLinkPaddingLeft {
    padding-right: 10px;
  }

.bookNow-container {
  margin-top: 10px;
  border: solid 3px var(--color-essence-orange);
  padding-bottom: 7px;
}

.bookNowLink {
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  color: var(--color-essence-orange);
}

.voucherLink {
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  color: var(--color-essence-orange);
  padding: 0px 5px 0px 5px
}

.voucher-button {
  margin-right: 10px;
}

/************************** TABLET **************************/
.App-header-tablet {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background);
  font-size: calc(10px + 2vmin);
  color: var(--color-text);
  width: 100%;
}

.header-container-tablet {
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.logoDimentions-tablet {
  height: 110px;
  width: 140px;
  transition: height 1;
  transition: width 1;
}

.logoContainer-tablet {
  display: flex;
  position: relative;
  border: 2px solid #8bac3e;
  z-index: 10;
  background-color: var(--color-background);
  left: 20px;
  top: 20px;
  height: 110px;
  width: 140px;
  transition: height 1;
  transition: width 1;
}

.header-container-start-tablet {
  width: 40%;
}

.header-container-end-tablet {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: flex-end;
}

.mediaLinksContainer-tablet {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
}

.tablet-banner-container {
  height: 184px;
  position: relative;
  top: -52px;
  display: flex;
}

.tablet-banner {
  object-fit: cover;
  object-position: 50% 25%;
  width: 100%;
  height: 184px;
}

/************************** MOBILE **************************/
.home-mobile {
  max-width: 360px;
  top: -27px;
  position: relative;
}

.App-header-mobile {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background);
  min-height: 10vh;
  max-height: 40vh;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: var(--color-text);
  max-width: 100%;
}

.header-container-mobile {
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.logoContainer-mobile {
  display: flex;
  position: relative;
  border: 2px solid #8bac3e;
  z-index: 10;
  background-color: var(--color-background);
  left: 20px;
  top: 20px;
  height: 110px;
  width: 140px;
  transition: height 1;
  transition: width 1;
}

.logoContainer-menu {
  display: flex;
  position: relative;
  border: 2px solid #8bac3e;
  z-index: 10;
  background-color: var(--color-background);
  left: 10px;
  top: 10px;
  height: 50px;
  width: 50px;
}

.logoDimentions-mobile {
  height: 110px;
  width: 140px;
  transition: height 1;
  transition: width 1;
}

.logoDimentions-menu {
  height: 50px;
  width: 50px;
}

.hamburger-container {
  display: flex;
  margin-right: 14px;
  margin-top: 14px;
  margin-left: 14px;
  height: 50px;
  width: 50px;
}

.open {
  background-color: #ededed;
}

.hamburger-icon {
  height: 50px;
  width: 50px;
}

.mobile-banner-container {
  height: 184px;
  position: relative;
  top: -52px;
}

.mobile-banner {
  height: 184px;
}

.hamburger-menu {
  background-color: #ededed;
  color: var(--color-essence-charcoal);
  width: 100%;
  position: absolute;
  height: 0%;
  z-index: 10;
  top: 60px;
  transition: height 0.5s;
}

.open-menu {
  height: 100%;
}

.menu-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-item {
  font-size: 30px;
  opacity: 1.0;
  transition: opacity 1s;
  color: var(--color-essence-orange);
  background-color: white;
  margin: 10px 10px 0px 10px;
  border: solid 2px var(--color-essence-orange);
}

.menu-item:hover {
  background-color: #c4c4c4;
}

.menu-item-closed {
  font-size: 0px;
  opacity: 0.0;
}