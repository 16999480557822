.privacy-body {
    display: flex;
    flex-direction: column;
    width: 1000px;
    text-align: left;
}

/************************** TABLET **************************/

.privacy-body-tablet {
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: left;
}

/************************** MOBILE **************************/
.privacy-body-mobile {
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: left;
}