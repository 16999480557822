.service-body {
    display: flex;
    flex-direction: column;
    width: 1000px;
    text-align: left;
}

.service-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.service-image {
    height: 360px;
}

.book-now-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    padding-top: 20px;
    padding-left: 20px;
    width: 100%;
}

.flex-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 15px;
}

.basic-single {
    width: 50%;
}

.label-length {
    width: 45%;
}

.buy-now {
    border: solid 3px var(--color-essence-orange);
    color: var(--color-essence-orange);
    width: 150px;
    text-align: center;
    margin-bottom: 20px;
}

/************************** TABLET **************************/

.service-body-tablet {
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: left;
}

.service-image-tablet {
    object-fit: cover;
    object-position: 50% 25%;
    width: 100%;
    height: 184px;
}

.book-now-container-tablet {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    width: 100%;
}
.buy-now-tablet {
    border: solid 3px var(--color-essence-orange);
    color: var(--color-essence-orange);
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

/************************** MOBILE **************************/
.service-body-mobile {
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: left;
}

.service-image-mobile {
    height: 200px;
}

.service-column {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.book-now-container-mobile {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    padding-top: 20px;
}

.flex-row-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
}

.service-price-mobile {
    margin-left: 20px;
}