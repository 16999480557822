.about-body {
    display: flex;
    flex-direction: column;
    width: 1000px;
    text-align: left;
}

.therapist-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

/************************** TABLET **************************/

.about-body-tablet {
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: left;
}

/************************** MOBILE **************************/
.about-body-mobile {
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: left;
}