.therapist-component {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-bottom: 20px;
}

.therapist-image {
    aspect-ratio: 665 / 931;
}

/************************** MOBILE **************************/
.therapist-component-mobile {
    display: flex;
    flex-direction: column;
    width: 320px;
    margin-bottom: 20px;
}

.therapist-image-mobile {
    width: inherit;
    aspect-ratio: 665 / 931;
}