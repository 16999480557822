.contact-body {
    display: flex;
    flex-direction: column;
    width: 1000px;
    text-align: left;
}

.link {
    color: var(--color-essence-orange);
    text-decoration: none;
}

.location-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}

.hours {
    width: 45%;
}

.google-map {
    width: 45%;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.details-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between ;
    height: 30px;
    margin-bottom: 15px;
}

.details-container {
    width: 50%;
}

.text-input {
    width: 95%;
    border-radius: 25px;
    padding: 5px;
    border-color: #767676;
}

.email-input {
    width: 98%;
    border-radius: 25px;
    padding: 5px;
    border-color: #767676;
}

.message {
    border-radius: 25px;
    padding: 5px;
    height: 60px;
    padding-top: 15px;
    border-color: #767676;
}

.contact-form-button {
    margin-top: 10px;
    margin-bottom: 10px;
    border: solid 3px var(--color-essence-orange);
    padding-bottom: 7px;
    padding-top: 7px;
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
    color: var(--color-essence-orange);
    text-align: center;
}

.contact-form-button:hover {
    cursor: pointer;
}

.contact-recaptcha {
    margin-top: 15px;
}

.loader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid var(--color-essence-orange);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .message-sent {
      color: var(--color-essence-orange);
  }

/************************** MOBILE **************************/
.contact-heading1-mobile {
    margin-top: 0px;
    margin-bottom: 0px;
}
.contact-heading2-mobile {
    margin-top: 5px;
    margin-bottom: 0px;
}

.contact-body {
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: left;
}

.location-container-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
}

.hours-mobile {
    width: 100%;
    margin-bottom: 30px;
}

.google-map-mobile {
    width: 100%;
}

.details-field-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px;
}

.details-container-mobile {
    width: 100%;
}

.text-input-mobile {
    width: 95%;
    border-radius: 25px;
    padding: 5px;
    border-color: #767676;
    margin-bottom: 5px;
}

.contact-form-button-mobile {
    margin-top: 10px;
    margin-bottom: 10px;
    border: solid 3px var(--color-essence-orange);
    padding-bottom: 7px;
    padding-top: 7px;
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
    color: var(--color-essence-orange);
    text-align: center;
}