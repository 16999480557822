.App-footer {
    width: 100%;
    border-top: solid 2px var(--color-essence-green);
}

.footer-container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.footer-container-quarter {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 25%;
}

.footerLogo {
    height: 160px;
    width: 160px;
}

.left-align {
    text-align: left; 
}

.messageContactContainer {
    border: solid 2px var(--color-essence-orange);
    height: 50px;
    width: 80%;
}

.footer-link {
  text-decoration: none;
  color: var(--color-essence-orange);
  padding-bottom: 5px;
}

.opening-hours {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.top-margin {
    margin-top: 16px;
}

.copyright-bar {
    background-color: #858585;
    margin-top: 30px;
}

.copyright-container {
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
}

/************************** TABLET **************************/

.footer-container-tablet {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.copyright-container-tablet {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
}

.footer-container-third {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 33%;
}

.footerLogo-tablet {
    height: 160px;
    width: 160px;
    margin: 0 auto;
}

.footer-link-tablet {
    text-decoration: none;
    color: var(--color-essence-orange);
    padding-bottom: 5px;
    width: 80%;
    margin: 0 auto;
  }

.messageContactContainer-tablet {
    border: solid 2px var(--color-essence-orange);
    height: 50px;
}

.contact-number-tablet {
    text-align: left;
}

.chch-address {
    margin-bottom: 0px;
}

/************************** MOBILE **************************/

.footer-container-mobile {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.footer-container-slice {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 80%;
    margin: auto;
}

.footer-link-mobile {
    text-decoration: none;
    color: var(--color-essence-orange);
    padding-bottom: 5px;
    margin-top: 8px;
    margin-left: 50px;
}

.copyright-bar-mobile {
    background-color: #858585;
    margin-top: 30px;
}

.copyright-container-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
}

.p-mobile-padding {
    margin: 0px;
    margin-top: 8px;
}