.voucher-body {
    display: flex;
    flex-direction: column;
    width: 1000px;
    text-align: left;
}

.vouchers-container {
    display: flex;
    flex-direction: column;
}

.vouchers-general {
    width: 45%;
}

.vouchers-thai {
    width: 45%;
}

.buy-now {
    border: solid 3px var(--color-essence-orange);
    color: var(--color-essence-orange);
    width: 150px;
    text-align: center;
    margin-bottom: 20px;
}

.bookNow-container {
    margin-top: 10px;
    border: solid 3px var(--color-essence-orange);
    padding-bottom: 7px;
  }
  
  .buyNowLink {
    font-weight: bold;
    text-decoration: none;
    color: var(--color-essence-orange);
  }

  .buyNowLink:hover {
    cursor: pointer;
  }

  .buyNowText {
      margin-top: 10px;
      margin-bottom: 10px;
  }

/************************** TABLET **************************/

.voucher-body-tablet {
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: left;

}

/************************** MOBILE **************************/
.vouchers-container-mobile {
    display: flex;
    flex-direction: column;
}

.voucher-body-mobile {
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: left;
}

.vouchers-general-mobile {
    width: 96%;
    display: flex;
    flex-direction: column;
}