html {
    --color-text: black;
    --color-background: #fff;
    --color-active-text: #ad4116;
    --color-essence-green: #96b451;
  }

.reviews-slider-container {
    background-color: var(--color-essence-green);
    height: 200px;
    display: flex;
    flex-direction: row;
}

.quote-container {
    height: 133px;
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: row;
}

.quote-image-container {
    width: 100px;
    height: 100%;
}

.quote-image {
    height: 50px;
    width: 50px;
    margin: auto;
}

.quote-text-container {
    display: flex;
    flex-direction: column;
    width: 1000px;
}

.quote-text {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-weight: lighter;
    color: #fff;
}

/************************** TABLET **************************/
.quote-container-tablet {
    height: 133px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
}

.quote-text-container-tablet {
    display: flex;
    flex-direction: column;
    width: 80%;
}