.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: calc(100% - 40px);
  height: calc(100vh - 100px);
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  padding: 50px 20px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 100;
}

.no-modal-wrapper {
  max-width: 500px;
  font-size: 16px;
  line-height: 30px;
  background-color: #a4d1c8;
  padding: 20px;
  margin-top: 50px;
  border-radius: 10px;
}

.modal {
  width: 100%;
  height: 100%;
  max-width: 600px;
  background-color: var(--color-background);
  position: relative;
  border: 5px;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  padding: 20px;
}

.timely-booking {
  height: 100%;
  width: 100%;
}