.carousel.carousel-slider {
    height: 480px;
    width: 100%;
  }

.home-services {
  display: flex;
  flex-direction: column;
  width: 1000px;
}

.video-container {
  display: flex;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px; 
}

/************************** TABLET **************************/

.home-tablet-body {
  width: 100%;
}

.video-container-tablet {
  display: flex;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
}

/************************** MOBILE **************************/
.home-mobile-body {
  width: 100%;
}

.home-links {
  text-decoration: none;
  color: var(--color-essence-orange);
  padding-bottom: 5px;
  margin-top: 8px;
}

.homeLinkContainer {
  border: solid 2px var(--color-essence-orange);
  height: 50px;
  width: 80%;
  /* margin-left: 50px; */
  margin: 0 auto;
  margin-bottom: 10px;
}

.bookNowLinkMobile {
  text-decoration: none;
}

.bookNowContainerMobile {
  background-color: var(--color-essence-orange);
  width: 80%;
  height: 50px;
  border-radius: 25px;
  margin: auto;
  
}

.bookNow {
  font-size: x-large;
  color: var(--color-essence-charcoal);
  padding-top: 8px;
}

.home-services-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
}