html {
  --color-text: black;
  --color-background: #fff;
  --color-active-text: #ad4116;
  --color-essence-green: #96b451;
  --color-essence-orange: #e06326;
  --color-essence-charcoal: #495057;
}

.App {
  text-align: center;
  color: var(--color-essence-charcoal);
  font-family: "Roboto",sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.contentBody {
  display: flex;
  width: 100%;
  justify-content: center;
}

.navLink {
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  color: var(--color-essence-orange);
}

.essence-orange {
  color: var(--color-essence-orange);
}

.opening-hours {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.banner-image {
  width: 100%;
}

.buy-now-mobile {
  border: solid 3px var(--color-essence-orange);
  color: var(--color-essence-orange);
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
}

.buy-now-mobile:hover {
  background-color: var(--color-essence-orange);
  color: white;
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
